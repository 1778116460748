import '../../../../vendor/assets/javascripts/metisMenu/jquery.metisMenu.js'
import '../../../../vendor/assets/javascripts/pace/pace.min.js'
import '../../../../vendor/assets/javascripts/datapicker/bootstrap-datepicker.js'
import '../../../../vendor/assets/javascripts/iCheck/icheck.min.js'
import '../../../../vendor/assets/javascripts/slimscroll/jquery.slimscroll.min.js'
import '../../../../vendor/assets/javascripts/switchery/switchery.js'

//custom.js bootstrap 
// $(function() {
$(document).on("turbolinks:load",function(){
  $('[data-toggle="tooltip"]').tooltip();
});

// $(function() {
$(document).on("turbolinks:load",function(){
  $('[data-toggle="popover"]').popover();
});
