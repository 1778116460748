// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import 'jquery'
import 'bootstrap'

import '@fortawesome/fontawesome-free/js/all';
import 'jquery-validation/dist/jquery.validate';
import 'select2/dist/js/select2.full';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import strftime from 'strftime'
import './custom'
import './inspinia'
// import 'blueimp-gallery'

Rails.start();
Turbolinks.start();
ActiveStorage.start()

global.strftime = strftime;
// global.blueimp = blueimp;

const loadInitials = () => {
  // feather.replace();
  logger('Find me in app/javascript/packs/application.js loadInitials');
}
const onReady = () => {
  $('.single_date_picker').each(function(){
    $(`input[name='${this.name}']`).daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      minDate: new Date($(this).attr('data-minDate')),
      maxDate: new Date(($(this).attr('data-maxDate') || new Date())),
      locale: {
           format: 'YYYY-MM-DD'
        }
    });
  });
  $('.date_range_picker').each(function(){
    $(`input[name='${this.name}']`).daterangepicker({
      showDropdowns: true,
      minDate: new Date($(this).attr('data-minDate')),
      maxDate: new Date(($(this).attr('data-maxDate') || new Date())),
      locale: {
           format: 'YYYY-MM-DD'
        }
    });
  });
  $("span[data-datetime],td[data-datetime],div[data-datetime]").each(function() {
      var timestamp = $(this).attr("data-datetime");
      if(timestamp != ''){
        var current_local_time = new Date(timestamp);
        var formated_localeTimestamp = strftime($.constant.datetime_format,current_local_time);
        this.title = formated_localeTimestamp + " || " + moment(current_local_time).fromNow();
        $(this).html(formated_localeTimestamp);
      }

  });
  $('select.select2').select2({});

  $('.alert').each(function(index, val) {
    setTimeout(function(){
      // alert('Hi')
      $(val).hide('slow');
    }, (index+1)*3000);
  });


  logger('Find me in app/javascript/packs/application.js OnReady');
}

var logger = (msg) => {
  console.log(msg);
}

$(document).on('turbolinks:load',function(){
  onReady.call();
  loadInitials.call();
  logger('Find me in app/javascript/packs/application.js turbolinks:load');
});

$(document).on('ajax:before','[data-remote]',() => {
  Turbolinks.clearCache();
  logger('Find me in app/javascript/packs/application.js ajax:before');
  onReady.call();
});

document.addEventListener('turbolinks:render', () => {
  logger('Find me in app/javascript/packs/application.js turbolinks:render');
  // To custome call me use this or auto called when turbolinks:render event triggered
  // document.dispatchEvent(new Event('turbolinks:render'));
});

document.addEventListener('turbolinks:partial:render', (e) => {
  Turbolinks.clearCache();
  loadInitials.call();
  logger('Find me in app/javascript/packs/application.js turbolinks:partial:render');
  // To call me use this
  // document.dispatchEvent(new Event('turbolinks:partial:render'));
}, false);

function fileinput(value){

  if (value.files && value.files[0]) {
   var a=value.files[0].name.split('.');
   var b = a[a.length-1];
   b=b.toLowerCase();
    if( b == 'png' || b == 'jpg' || b=="JPG" || b == 'jpeg' || b == 'mp4')
    {
    if(b == "mp4" && value.files[0].size/(1000*1024*10) > 1){
      alert("Please choose file less then 10 MB")
      return
    }
    else
    {
      if(value.files[0].size/(1000*1024*2) > 1)
      {
        alert("Please select the File less then 2 MB");
        return
      }

    }
    var reader = new FileReader();
    debugger;
    if(b != "mp4"){
      reader.onload = function (e) {
        $(value).parent().find('.fileinput-preview ').next('video')
          .attr('src', '')
          .hide();
        $(value).parent().find('.fileinput-preview ').show();
        $(value).parent().find('.fileinput-preview ') //finds the parent div and gets the img_prev contained inside
        .attr('src', e.target.result)
      };
    }
  else{
     // $(".fileinput-preview").hide();
     //finds the parent div and gets the img_prev contained inside
    reader.onload = function (e) {
      $(value).parent().find('.video_preview ').prev('img').hide();
      $(value).parent().find('.video_preview ').attr({'src': e.target.result,"controls":"true"})

    };
   }
    reader.readAsDataURL(value.files[0]);
    }
    else
    {
      alert("You're only allowed to upload jpg or png images.");
      value.value = '';
    }
  }
}

function dynamic_img_field(evt){
  var d = Math.random().toString(36).substring(7);
  var l = $(evt).siblings().children('label').slice(-1);

  $(l).children('input.hide').attr('id', d);
  $(l).attr('for', d);
}

// direct_uploads.js

addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
  target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})